.about-page-wrapper{
    display: grid;
    grid-template-rows: 12vh;
    grid-template-columns: 1fr;
    justify-content: center;
    @include respond(tab-port) {
    }
    @include respond(tab-land) {
        grid-template-rows: 10vh 20vh 22vh 160vh 45vh;
        grid-template-columns:repeat(12, 1fr);
    }
    @include respond(big-desktop) {
        grid-template-rows: 10vh 20vh 22vh 120vh 45vh;
    }
}

.title{
    grid-row: 2 / 3;
    @include centering-titles-mobile;
    @include titlesPages;
    @include respond(tab-land) {
      grid-column: 5 / 9;
      margin-top: 4rem;
    }
}
.title-text {
    letter-spacing: -.2rem;

}
.title-underline {
    width: 80%;
    height: .6rem;
    background-image: linear-gradient(to right, #D2E603, #3E978B);
    @include respond(tab-land) {
        width: 100%;
    }
}
.picture {
    grid-row: 3 / span 1;
    @include respond(tab-land) {
        grid-column: 2 / span 3;
    }
}

.picture-container {
    display: flex;
    justify-content: center;

}
.picture-me {
    @include pictureSize;
}
.content {
    grid-row: 4 / span 1;
    color: $color-light;
    font-size: 2.4rem;
    font-weight: 400;
    padding: 0 7%;
    @include respond(tab-land) {
        grid-row: 3 / span 1;
        grid-column: 5 / span 6;
        font-size: 2.4rem;
        padding: 0 7% 0 0;
    }
    @include respond(big-desktop) {
        font-size: 2.4rem;
        padding: 0 7% 0 0;
    }

}
.content-text {
    margin-top: 1rem;
    span {
        color: $color-primary;
    }
    @include respond(tab-land) {
      margin-top: 2.5rem;
    }
}
.content-list {
    color: $color-primary;
    list-style: none;
}
.content-logos {
    display: flex;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    margin: 10% 0;
    @include respond(tab-port) {
        margin: 5% 0;
    }
}
.content-logos-skills {
    max-width: 7rem;
    @include respond(tab-port){
        margin: 0 2rem;
    }
}
.content-logo {
    width: 80%;
    @include respond(big-desktop) {
        width: 100%;
    }
}

