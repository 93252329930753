.project-page-wrapper{
    display: grid;
    grid-template: 10vh 18vh 100vh 35vh 95vh 180vh  190vh 75vh 10vh / 1fr;
    grid-template-areas:
    "header"
    "title"

    "result"
    "header-project"
    "project-intro"
    "challenge"
    "research"

    "bottonContact"
    "footer"
    ;
    justify-content: center;
    color: $color-light;
    font-size: 2rem;
    font-weight: 400;
    @include respond(base) {
        grid-template: 12vh 16vh  100vh 40vh 80vh 155vh  190vh 52vh 10vh / 1fr;
        grid-template-areas:
        "header"
        "title"

        "result"
        "header-project"
        "project-intro"
        "challenge"
        "research"

        "bottonContact"
        "footer"
        ;
        }
    @include respond(phone) {
        grid-template: 12vh 16vh 110vh 40vh 80vh 140vh  190vh 52vh 10vh / 1fr;
        grid-template-areas:
        "header"
        "title"

        "result"
        "header-project"
        "project-intro"
        "challenge"
        "research"

        "bottonContact"
        "footer"
        ;
        }

        @include respond(tab-port){
        grid-template: 10vh 15vh 105vh 35vh 50vh 140vh 140vh 40vh 10vh / 1fr;
        grid-template-areas:
        "header"
        "title"

        "result"
        "header-project"
        "project-intro"
        "challenge"
        "research"

        "bottonContact"
        "footer"
        ;
        }
        @include respond(tab-land){
        grid-template: 10vh 15vh 110vh 45vh 60vh 110vh 110vh 52vh 10vh / 1fr;
        grid-template-areas:
        "header"
        "title"

        "result"
        "header-project"
        "project-intro"
        "challenge"
        "research"

        "bottonContact"
        "footer"
        ;
        }
}

.title-projects{
    grid-area: title;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 3rem;
}
.title-underline-project {
    width: 35%;
    height: .5rem;
    margin-top: .6rem;
    background-image: linear-gradient(to right, #D2E603, #3E978B);
}

.header-project {
    grid-area: header-project;
    height: 35vh;
    display: flex;
    color: $color-light-gray;
    @include respond(tab-land) {
        height: 45vh;
    }

}
// headers for all projects
.header-ushome-imageRigth {
    @include headersRigth;
    background-image: linear-gradient(to right, rgba(46, 196, 172, .8),
    rgba(46, 196, 172, .8)), url(../../../public/img/project-ushome-images/final-2.png);
}
.header-ushome-imageLeft {
    @include headersLeft;
    background-image: url(../../../public/img/project-ushome-images/final-1.png);
}


.header-megafruver-imageRigth {
    @include headersRigth;
    background-image: linear-gradient(to right, rgba(75, 151, 39, 0.8), rgba(94, 143, 38, 0.8)), url(../../../public/img/project-megafruver-images/headerRigth-megafruver.png);
}
.header-megafruver-imageLeft {
    @include headersLeft;
    background-image: url(../../../public/img/project-megafruver-images/headerLeft-megafruver.png);
}

.header-woodsidevision-imageRigth {
    @include headersRigth;
    background-image: linear-gradient(to right, rgba(36, 44, 32, 0.8), rgba(46, 51, 41, 0.8)), url(../../../public/img/project-woodsidevision-images/headerRigth-woodsidevision.png);
}
.header-woodsidevision-imageLeft {
    @include headersLeft;
    background-image: url(../../../public/img/project-woodsidevision-images/headerLeft-woodsidevision.png);
}

.header-jtportfolio-imageRigth {
    @include headersRigth;
    background-image: linear-gradient(to right, rgba(36, 44, 32, 0.8), rgba(46, 51, 41, 0.8));
}
.header-jtportfolio-imageLeft {
    @include headersLeft;
    background-image: url(../../../public/img/project-jtportfolio-images/bg-work.png);
}

// end headers for all projects


.header-title {
    font-size: 2.2rem;
    @include respond(tab-port) {
        font-size: 3.5rem;
    }
    @include respond(tab-land) {
        font-size: 3.9rem;
    }
}
.header-subtitle {
    font-size: 1.6rem;
    @include respond(tab-port) {
        font-size: 2rem;
    }
    @include respond(tab-land) {
        font-size: 2.5rem;
    }
}
#project-intro {
    grid-area: project-intro;
    display: flex;
    flex-direction: column;
   height: 100%;
   width: 100%;
   padding: 0 7%;
   @include respond(tab-port) {
       flex-direction: row;
   }
}
.project-intro--text {
    width: 100%;
    padding: 0 7%;
    margin-top: 20%;
    font-size: 2rem;
    @include respond(phone) {
        font-size: 2.3rem;
    }
    @include respond(tab-port) {
        width: 55%;
        margin-top: 20%;
        padding: 0 1%;
    }
    @include respond(tab-land){
        margin-top: 12%;
    }
}
.project-technologies {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20%;
    height: 100%;
    @include respond(tab-port) {
        margin-top: 10%;
        width: 45%;
    }
    @include respond(tab-land){
        margin-top: 2%;
    }
}
.project-technologies--line {
    height: 100%;
    display: flex;
    width: 25%;
    justify-content: flex-end;
    @include respond(tab-port) {
        height: 70%;
        width: 5%;
    }
};

.project-line {
    width: 1rem;
    height: 100%;
    background-color: $color-box-teal;
}

.project-technologies--text {
    width: 60%;
    h2 {
        font-size: 2.4rem;
        color:$color-primary;
    }
};
.project-li {
    list-style: none;
    font-size: 2rem;
}
#challenge {
    grid-area: challenge;
    padding: 12% 14%;
    @include respond(tab-land) {
        padding: 0 7%;
    }
}
.challenge-title, .research-title, .result-title {
    color: $color-primary;
    font-size: 2.5rem;
    @include respond(phone) {
        font-size: 3rem;
    }
    @include respond(tab-port) {
        font-size: 4.5rem;
    }
}
.research-title {
 margin-top: 3rem;
}
.challenge-text, .research-text {
    margin-top: 5rem;
    font-size: 2rem;
    @include respond(phone) {
        font-size: 2.3rem;
    }
    @include respond(tab-land) {
        width: 100%;
        padding-left: 35%;
    }
}
.challenge-container,.research-container {
    display: flex;
    flex-direction: column;
}
.challenge-img-cont, .research-img-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include respond(tab-land) {
        flex-direction: row;
        justify-content: space-around;
        padding-left: 0;
    }
}
.challenge-img, .research-img {
    width: 100%;
    margin-top: 3rem;
    @include respond(tab-land) {
        width: 48%;
    }
}
.project-images {
    width: 100%;
}
#research {
    grid-area: research;
    padding: 0 14%;
    @include respond(tab-land) {
        padding:  0 7%;
    }
}


.result-title {
    padding: 0 7%;
    @include respond(tab-land) {
        padding: 0 7%;
    }
}

.result-visit-link {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    & a {
        color: $color-light-gray;
    }
    & a:hover{
        color: $color-primary;
    }
}
