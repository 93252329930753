*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
a {
    text-decoration: none;
}
html{
    font-size: 62.5%;  //defines what 1 rem is = 10px/16px = 62.5%
    @include respond(base) {
        // font-size: 62.5%;
    }
    @include respond(phone){
        // font-size: 80%;
    }
    @include respond(tab-port){
        // font-size: 90%;
    }
    @include respond(tab-land){
        // font-size: 95%;
    }
    @include respond(big-desktop){
        // font-size: 100%;
    }

}
body{
    background-color: $color-dark;
    box-sizing: border-box;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    letter-spacing: -.1rem;
    color: $color-light-gray;
}
