
#intro {
    grid-row: 2 /3;
    display: flex;
    justify-content: center;
    line-height: 3.2rem;
    padding: 0 3.8rem;
}
.mainBox {
    height: 75vh;
    width: 100%;
    background-image: linear-gradient(to right, #D2E603, #3E978B);
    padding: 10px;
    @include respond(tab-port) {
        width: 90%;
        height: 85vh;
    }

}
.mainBox-inside {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: $color-dark;
    height: 100%;
    padding: 0 7%;
    @include respond(tab-port) {
        padding: 11% 8% 0 8%;
    }
    @include respond(tab-land) {
        padding-top: 7%;
    }
    @include respond(big-desktop) {
        padding-top: 0;
    }
}
.mainBox-note {
    color:$color-invisible-gray;
    font-family: kalam;
    font-size: 1.8rem;
    padding: 10px 0;
}
.mainBox-intro {
    justify-content: center;
    font-weight: 400;
    color:$color-light;
    font-size: 2.7rem;
    line-height: 3.5rem;
    @include respond(phone) {
        font-size: 3rem;
        line-height: 3.5rem;
    }
    @include respond(tab-port) {
        font-size: 3rem;
        line-height: 6.5rem;
    }
    @include respond(tab-land) {
        font-size: 3.5rem;
        line-height: 5.8rem;
    }
    @include respond(big-desktop) {
        font-size: 5.5rem;
        line-height: 7.5rem;
    }
    span {
        color: $color-primary;
        font-weight: 500;
        font-size: 6rem;
        letter-spacing: -.5rem;
        @include respond(phone) {
            // font-size: 3.9rem;
        }
        @include respond(tab-port) {
            font-size: 8rem;
            line-height: 6rem;
        }
        @include respond(tab-land) {
            // font-size: 6rem;
        }
        @include respond(big-desktop) {
            font-size: 10rem;

        }
    }
}
.mainBox-down {
    display: flex;
    justify-content: flex-end;
    padding: 3rem 3.5rem 0 0;
    @include respond(tab-port) {
        padding: 2rem 3.5rem 0 0;
    }
    @include respond(big-desktop) {
        padding: 5rem 3.5rem 0 0;
    }
}

.mainBox-botton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    @include respond(tab-port) {
        height: 9rem;
        width: 9rem;
    }
    .mainBox-boton-text {
      animation: rotation 30s infinite linear;
      height: 9rem;
      width: 9rem;
      justify-content: center;
      font-size: 3rem;
      letter-spacing: 0;
      @include respond(tab-port) {
        font-size: 1.5rem;
        height: 13rem;
        width: 13rem;
      }
    }

}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
