$color-primary: #D2E603;
$color-primary-dark: #96a307;
$color-dark: #1F1F1F;
$color-light: #CACACA;
$color-light-gray: #E5E5E5;
$color-darker-gray:#363636 ;
$color-ligther-gray: #8E8E8E;
$color-invisible-gray: #4e4e4e;
$color-box-teal: #2EC1AC;
$color-box-teal-hover:#3ed6bf;
$color-home-intro: #595959;
$color-react-logo: #00d8fd;