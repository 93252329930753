
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-darker-gray;
    color: $color-light;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem 0;
    opacity: .4;
    p {
        font-size: 1.3rem;
    }
}
.footer a:visited {
    color: $color-light;
}
.footer a:hover{
    color: $color-primary;
}

.footer-links {
        margin: 0 .5rem;
}
