.work-page-wrapper{
    display: grid;
    justify-content: center;
    @include respond(base) {
    }
    @include respond(phone) {
        }
    @include respond(tab-port) {
        }
    @include respond(tab-land) {
        }
}

.title-work {
    grid-area: title-work;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 3rem;

}
.work-title-underline {
    width: 60%;
    height: .7rem;
    background-image: linear-gradient(to right, #D2E603, #3E978B);
}
#content-work {
    grid-area: content-work;
    justify-content: center;
    padding: 0 7%;

    @include respond(tab-port) {
        // display: flex;
        // flex-direction: row;
        // height: 50vh;
    }
    @include respond(tab-land) {
        height: 60vh;
    }
}
.work-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: $color-light;
    // display: grid;
    // grid-gap: 2rem;
    // grid-template-rows: repeat(4, minmax(40vh, min-content));
    // grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    align-items: center;
    @include respond(phone) {
        // grid-template-rows: repeat(2, minmax(40vh, min-content));
        }
    @include respond(tab-port) {
        // grid-template-rows: repeat(2, minmax(40vh, min-content));
        }
    @include respond(tab-land) {
    // grid-template-rows: repeat(1, minmax(40vh, min-content));
    }
}
.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // justify-content: space-between;
    // border: solid 2px gray;
    margin: 5.5rem 0;
    text-align: center;
    width: 100%;
    min-height: 60vh;

    @include respond(base) {
        font-size: 2rem;
    }
    @include respond(tab-port) {
        flex-direction: row;
        min-height: 45vh;
    // text-align: center;
    // font-size: 1.1rem;
    }
    @include respond(big-desktop) {
        min-height: 50vh;
        }
}

.item_split-text {
    width: 100%;
    text-align: left;
    @include respond(tab-port) {
        font-weight: 200;
        font-size: 2rem;
        padding: 0 5rem;
    }
    @include respond(big-desktop) {
        font-size: 3rem;
        margin-left: 5rem;
    }
}

.item-titles {
    font-size: 5.5rem;
    font-weight: 600;
    letter-spacing: -.2rem;
    line-height: 3.9rem;
    margin-bottom: 2rem;
    @include respond(tab-port) {
        line-height: 5rem;
        margin-bottom: 3.5rem;
        font-size: 6rem;
    }
    @include respond(tab-land) {
        line-height: 6.5rem;
        margin-bottom: 3.5rem;
        font-size: 8.5rem;
    }
    @include respond(big-desktop) {
        line-height: 9rem;
        margin-bottom: 3.5rem;
        font-size: 12rem;
    }
}
.item-titles span {
    margin-left: 1.8em;
    @include respond(tab-port){
        margin-left: 13rem;
    }
    @include respond(big-desktop) {
        margin-left: 24rem;
    }

}
.view-project {
    text-align: right;
    @include respond(tab-port){
        margin-right: 3rem;
    }
}
.view-project a {
    color: #D2E603;
    font-size: 2.5rem;
    opacity: .3;
    @include respond(tab-port) {
        font-size: 2.5rem;
    }
}
.view-project a:hover {
    opacity: .9;
    cursor: pointer;
}
.item_split-image {
    display: flex;
    font-size: 1.3rem;
    min-height: 35vh;
    width: 100%;
    background-position: center;
    background-size:contain;
    background-repeat: no-repeat;
    @include respond(tab-land) {
        font-size: 1.2rem;
        @include respond(tab-port) {
            font-size: 1.2rem;
            min-height: 50vh;
        }
        @include respond(tab-land) {
            font-size: 1.2rem;
        }
    }
}
.bgItem-1 {
    background-image: url(../../../public/img/super_store.png);
    @include respond(tab-port) {
    }
}

.bgItem-2 {
    background-image: url(../../../public/img/geek_hunt.png);
    @include respond(tab-port) {
    }
}
.bgItem-3 {
    background-image: url(../../../public/img/us_home.png);
    @include respond(tab-port) {
    }
}
.bgItem-4 {
    background-image: url(../../../public/img/megafruver.png);
    @include respond(tab-port) {
    }
}
