#result {
    grid-area: result;
    @include respond(port) {
        padding: 0 14%;
    }
    @include respond(tab-land) {
        padding: 0
    }
}

.result-content {
    display: flex;
    height: 70vh;    
    margin-top: 4%;
    background-color: $color-box-teal;
    @include respond(tab-land) {
    height: 75vh;    
    margin-top: 1%;
    padding:  0 10%;
    }

}

.slidercontainer {
    display: flex;
    justify-content: center;
    height: 65%;
    max-width: 40%;     
    position: relative;  
    margin: auto;  
    @include respond(phone) {
        height: 74%;
    }
    @include respond(tab-port){
    height: 100%;
    max-width: 80%; 
    }
}

.showSlide {
    // display: none;
    display: flex; 
    height: 75%;
    align-items: center;
    @include respond(tab-port) {
        height: 100%;
    }
    & img {
        display: flex;
       height: 100%;
       @include respond(phone) {
        height: 80%;
       }
       @include respond(tab-land) {
        height: 100%;
       }
    }
}

.fade {  
    -webkit-animation-name: fade;  
    -webkit-animation-duration: 1.5s;  
    animation-name: fade;  
    animation-duration: 1.5s;  
    justify-content: center;    
    width: 60%;
}  

.left, .right {  
    cursor: pointer;  
    top: 50%;  
    width: auto;  
    padding: 18rem 2.3rem; 
    color: white;  
    font-weight: bold;  
    font-size: 2rem;  
    transition: 0.3s ease;  
    border-radius: 0 .2rem .2rem 0; 
    @include respond(port) {
        font-size: 4rem;  
    } 
}  
.right {  
    right: 0;  
    border-radius: .3rem 0 0 .3rem;  
}  
    // .left:hover, .right:hover {  
    //     background-color: rgba(115, 115, 115, 0.8);  
    // }  
@-webkit-keyframes fade {  
    from {  
        opacity: .1 ;
        transform: translatey(5%);
    }  
    to {  
        opacity: 1 ;
        transform: translatey(0);
    }  
}  

@keyframes fade {  
    from {  
        opacity: .1 ;
        transform: translatey(5%);
    }  
    to {  
        opacity: 1;
        transform: translatey(0); 
    }  
}  

.project-btns {
    display: flex;
    justify-content: center;
}

.result-btn-style {
    padding: 3%;
    margin: 5% 2%;
    width: 35%;
    font-size: 1.5rem;
    text-align: center;
    background-color: $color-box-teal;
    color: $color-light-gray;
    border: 0 solid;
    @include respond(phone) {
        font-size: 1.5rem;
    }
    @include respond(tab-port) {
        font-size: 2rem;
        width: 29%;
    }
    @include respond(tab-land) {
        font-size: 2rem;
        margin: 2% 2%;
        padding: 1%;
        width: 15%;
    }
}

.result-btn-style:hover {
    background-color: $color-box-teal-hover;
    color:$color-darker-gray;
    cursor: pointer;
}