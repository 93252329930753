.home-page-wrapper{
    display: grid;
    grid-template-rows: 12vh;
    // justify-content: center;
    @include respond(tab-port) {
    }

    @include respond(phone) {

    }
    @include respond(tab-port)  {
    }
    @include respond(tab-land) {

    }
    @include respond(big-desktop) {
    }
}

.middle_divider{
    grid-row: 3 / 4;
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    padding: 0 2%;
    align-items: center;
    margin: 4.5rem 0 1rem 0;
    @include respond(tab-port) {
        padding: 0 12% 0 16%;
        justify-content: center;
        flex-direction: row;
    }

}
.middle_divider-split {
    padding: 2rem;
    flex-direction: row;
    margin-top: 2rem;
    @include respond(tab-port) {
        padding: 0 1.8rem 0 1rem;
        min-width: 40vw;
    }
}

.middle_divider-col {
    padding: 1.7rem;
    @include respond(tab-port) {
        max-width: 40vw;
    }
    @include respond(big-desktop) {
        max-width: 20vw;
    }
}
.middle_divider-phrase {
    letter-spacing: -.1rem;
    font-size: 6rem;
    font-weight: 500;
    line-height: 4rem;
    max-width: 70vw;
    @include respond(tab-port) {
        line-height: 6rem;
        max-width: 35vw;
        font-size: 9rem;
        letter-spacing: -.6rem;
    }
    @include respond(big-desktop) {
        line-height: 11rem;
        max-width: 35vw;
        font-size: 15rem;
        letter-spacing: -.6rem;
    }
}
.middle-text {
    font-size: 2.4rem;
}
