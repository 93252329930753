#process {
    // grid-area: process;
}
.process-container {
    background-color: $color-light-gray;
    clip-path: polygon(0 0, 100% 1.5%, 100% 98.5%, 0 100%);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:  5% auto;
    @include respond(phone) {
        clip-path: polygon(0 0, 100% 1.5%, 100% 98.8%, 0 100%);
    }
    @include respond(tab-port) {
        flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: center;
    }
    @include respond(big-desktop) {
        padding: 0 14%;
    }
}
.process-title:first-child {
    width: 100%;
  }
.process-container:not(:first-child) {
    flex: 1;
  }

.process-title {
    font-size: 4.2rem;
    font-weight: 900;
    color:$color-darker-gray;
    margin-top: 10%;
    letter-spacing: -0.2rem;
    padding-left: 7%;
    @include respond(phone) {
      font-size: 5.6rem;
    }
    @include respond(tab-port) {
      font-size: 7rem;
      margin-top: 0;
      text-align: left;

    }
    @include respond(big-desktop) {
        font-size: 10rem;
        padding-left: 0;
    }
}
.process-steps {
    min-height: 360px;
    width: 92%;
    margin-top: 3rem;
    background-color: $color-dark;
    text-align: center;
    @include respond(tab-port) {
      width: 31%;
      height: 58%;
  }
  @include respond(big-desktop) {
      width: 30%;
      height: 58%;
  }
}
.process-subtitles {
  color: $color-primary;
  font-size: 3rem;
  margin: 3% 0;
  @include respond(big-desktop) {
    font-size: 3.5rem;
    margin: 3% 0;
  }
}
.process-img {
    width: 50%;
    margin: 0 auto;
}
.process-img-size {
  width: 100%;
}
.process-text {
    color: $color-light;
    font-size: 1.8rem;
    letter-spacing: 0;
    padding: 5% 7%;
    text-align: left;
    @include respond(phone) {
        font-size: 1.7rem;
        adding: 0 6%;
    }
    @include respond(tab-port) {
    font-size: 1.6rem;
    }
    @include respond(big-desktop){
        font-size: 2rem;
      padding: 0 7%;
    }
}


