#bottonContact {
    grid-row: 5 / span 1;
    @include respond(tab-port) {
      grid-column: 1 / -1;
      }
 }
.bottonContact-container {
    height: 100%;
    clip-path: polygon(0 9%, 100% 2%, 100% 97%, 0 90%);
    background-color: $color-darker-gray;
    color: $color-ligther-gray;
    font-size: 2.2rem;
    padding: 14% 7%;
    @include respond(base) {
    }

    @include respond(phone) {
      font-size: 2.2rem;
      }
    @include respond(tab-port) {
    font-size: 2.4rem;
    // margin-top: 5rem;
    }
    @include respond(tab-land) {
      text-align: center;
      padding: 5% 15%;
    }
    @include respond(big-desktop) {
      font-size: 3rem;
      text-align: center;
    }
}

.bottonContact-text {
  letter-spacing: -.1rem;
  @include respond(big-desktop) {

  }
  span {
      color: $color-primary;
  }
}
.bottonContact-link {
  color: $color-primary;
  font-size: 2.2rem;
  &:hover {
    opacity: .5;
    cursor: pointer;
  }
}



