#header {
    grid-area: header;
    z-index: 10;
}
.fixedmenu {
    position: fixed;
    top: 0;
    height: 10vh;
    width: 100%;
    background: rgba(27, 27, 27, 0.9);
    z-index: 2;
}
.logo {
    position: fixed;
    right: 7%;
    top: 3%;
    color: $color-light-gray;
    font-size: 1.7rem;
    line-height: 1.7rem;
    border-radius: 2px;
    padding: .5rem;
    z-index: 10;
    @include respond(tab-port) {
        right: 7%;
        top: 3%;
        font-size: 2.3rem;
    }
    span {
        color: $color-ligther-gray;
    }
    .logo-note {
        color:$color-ligther-gray;
        font-family: kalam, sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
    }
}

h1 {
    color: $color-primary;

}
li .menu {
    color: $color-primary;
    list-style: none;
    font-size: 2em;
}
.nav {
    position: fixed;
    top: 0;
    left: 7%;
    background-color: rgba(0,0,0,.9);
    z-index: 10;
}
.nav_burguer {
    position: fixed;
    left: 7%;
    top: 3%;
    width: 3.5rem;
    margin: 0 0;
    cursor: pointer;
    z-index: 100;
    @include respond(tab-port) {
        left: 7%;
        top: 5%;
    }
  }
  .nav_burguer--1, .nav_burguer--3 {
      margin: .2rem auto;
      border-radius: .2rem;
      width: 100%;
      height: .5rem;
      background-color: $color-primary;
      transition: all .4s ease-out;
  }
  .nav_burguer--2 {
      margin: .5rem auto;
      border-radius: .2rem;
      width: 100%;
      height: .5rem;
      background-color: $color-primary;
      transition: all .4s ease-out;
  }
  nav .nav_call {
    position: absolute;
    right: 4%;
    top: 63%;
    background-color: #eebb4d;
    border-radius: .5rem;
    height: 40px;
    width: 30%;
    font-size: 2rem;
    text-align: center;
    padding-top: .2rem;
}
.nav_call--text {
    text-decoration-line: none;
    color: #fff;
}
.nav_b1--active {
    box-shadow: 0px 3px 13px rgba(0,0,0,.4);
    transform: translate(0, 10px) rotate(45deg);
}
.nav_b2--active {
    box-shadow: 0px 3px 13px rgba(0,0,0,.4);
    transform: rotate(280deg);
    opacity: 0;
}
.nav_b3--active {
    box-shadow: 0px 3px 13px rgba(0,0,0,.4);
    transform: translate(0,-10px) rotate(-45deg);
}

.nav_ul {
    background-color: rgba(0,0,0,0.9);
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: all .5s ease-out;
    pointer-events: none;
    padding: 20% 5%;
    @include respond(tab-land) {
        padding: 15% 5%;
    }
}

.nav_ul--open {
    clip-path: polygon(0 0, 60% 0, 60% 100%, 0 100%);
    pointer-events: all;
    z-index: 10;
    @include respond(tab-port) {
        clip-path: polygon(0 0, 55% 0, 55% 100%, 0 100%);
    }
}
.nav_li {
    display:flex;
    font-size: 2.4rem;
    align-items: flex-start;
    margin:2.5rem .5rem;
    height: 10%;
    width: 60%;
    @include respond(tab-land) {
        height: 30%;
        margin:2rem .5rem;
    }
}
.nav_li--link {
    text-decoration: none;
    color: rgb(238, 238, 238);
    letter-spacing: -.4rem;
    font-size: 4.5rem;
    font-weight: 600;
    @include respond(tab-port) {
        font-size: 8rem;
    }
}
.nav_li--link:hover {
    color:$color-primary;
    cursor: pointer;
}
a {
    color: $color-light;
}
