.contact-page-wrapper{
    display: grid;
    grid-template: 10vh 16vh 38vh 95vh 10vh / 1fr;
    grid-template-areas:
    "header"
    "title"
    "contact-picture"
    "contact-content"
    "footer"
    ;
    justify-content: center;
    @include respond(base){
        grid-template: 10vh 16vh 32vh 85vh 10vh / 1fr;
    grid-template-areas:
    "header"
    "title"
    "contact-picture"
    "contact-content"
    "footer"
    ;
    }
    @include respond(phone){
        grid-template: 10vh 16vh 29vh 55vh 10vh / 1fr;
    grid-template-areas:
    "header"
    "title"
    "contact-picture"
    "contact-content"
    "footer"
    ;
    }
    @include respond(tab-port){
        grid-template: 10vh 15vh 25vh 39vh 10vh / 1fr;
    grid-template-areas:
    "header"
    "title"
    "contact-picture"
    "contact-content"
    "footer"
    ;
    }
    @include respond(tab-land){
        grid-template: 10vh 20vh 60vh 10vh / repeat(12, 1fr);
        grid-template-areas:
        "header header header header header header header header header header header header"
        ". . . . title title title title title title title ."
        "contact-picture contact-picture contact-picture contact-picture contact-content contact-content contact-content contact-content contact-content contact-content contact-content contact-content"
        "footer footer footer footer footer footer footer footer footer footer footer footer"
    ;
    }
 }


// .title{
//     grid-area: title;
//     @include centering-titles-mobile;
// }

.contact-picture {
    grid-area: contact-picture;
    display: flex;
    justify-content: center;

}
.picture-contact {
    @include pictureSize;

}

.contact-content {
    grid-area: contact-content;
    color: $color-light-gray;
    font-size: 2.8rem;
    font-weight: 400;
    padding: 0 7%;
    margin-top: 3rem;
    @include respond(tab-land) {
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    @include respond(tab-land) {
        font-size: 2.6rem;
    }
    @include respond(big-desktop) {
        font-size: 3rem;
    }

}
.content-email {
    text-align: center;
    font-size: 2.2rem;
    @include respond(tab-port){
        font-size: 6rem;
    }
    @include respond(tab-land){
        text-align: left;
    }
}
.content-box-text {
    width: 100%;
    background-color: $color-invisible-gray;
    text-align: center;
    padding: .2rem 2rem;
    margin-top: 1rem;
    @include respond(tab-port){
        height: 25vh;
    }
    @include respond(tab-land){
        height: 27vh;
        text-align: left;
    }
}
.content-text-contact {
    span {
        color: $color-primary;
    }
    padding: 1rem 0;
    @include respond(tab-port){
        font-size: 3.1rem;
        margin-top: 5%;
    }
    @include respond(tab-land) {
        margin-top: 2%;
    }
}
